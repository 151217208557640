/* @import 'colors.scss'; */
@import "variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "theme.scss";
@import "colors.scss";
@import "hero.scss";
@import "timeline.scss";

* {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

html {
   scroll-behavior: smooth;
}

.color-main {
  background-color: $color-main;
}

.color-normal {
  background-color: $normal;
}

.color-lightest {
  background-color: $lightest;
}

.font-lightest {
  color: $lightest;
}

.font-white {
  color: $color-white !important;
}

.color-light {
  background-color: $light;
}

.slick-prev:before,
.slick-next:before {
    color: $color-main !important;
  }

.headroom--unfixed {
  background-color: $primary;
}

.headroom--pinned {
  z-index: 3 !important;
  background-color: $primary;
}

.fade-in {
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
