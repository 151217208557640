// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap" !default;
@import url($web-font-path);
@import "variables.scss";

// Navbar ======================================================================

.navbar {
  font-size: $font-size-sm;
}

// Typography ==================================================================

.dropdown-menu {
  background-color: $light;
  font-size: $font-size-sm;
}

.dropdown-header {
  font-size: $font-size-sm;
}

.blockquote-footer {
  color: $body-color;
}

// Tables ======================================================================

.table {
  font-size: $font-size-sm;

  .thead-dark th {
    color: $white;
  }

  a:not(.btn) {
    color: #fff;
    text-decoration: underline;
  }

  .dropdown-menu a {
    text-decoration: none;
  }

  .text-muted {
    color: $text-muted;
  }

  &-dark {
    color: #fff;
  }

  &-primary {
    &, > th, > td {
      background-color: $primary;
    }
  }

  &-secondary {
    &, > th, > td {
      background-color: $secondary;
    }
  }

  &-light {
    &, > th, > td {
      background-color: $light;
    }
  }

  &-dark {
    &, > th, > td {
      background-color: $dark;
    }
  }

  &-success {
    &, > th, > td {
      background-color: $success;
    }
  }

  &-info {
    &, > th, > td {
      background-color: $info;
    }
  }

  &-danger {
    &, > th, > td {
      background-color: $danger;
    }
  }

  &-warning {
    &, > th, > td {
      background-color: $warning;
    }
  }

  &-hover {

    .table-primary:hover {
      &, > th, > td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {
      &, > th, > td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {
      &, > th, > td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {
      &, > th, > td {
        background-color: darken($dark, 5%);
      }
    }

    .table-success:hover {
      &, > th, > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &, > th, > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &, > th, > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &, > th, > td {
        background-color: darken($warning, 5%);
      }
    }
  }
}

// Forms =======================================================================

label,
.radio label,
.checkbox label,
.help-block {
  font-size: $font-size-sm;
}

// Navs ========================================================================

.nav-tabs,
.nav-pills {
  .nav-link,
  .nav-link:hover {
    color: $body-color;
  }

  .nav-link.disabled {
    color: $nav-link-disabled-color;
  }
}

.page-link:hover,
.page-link:focus {
  color: #fff;
  text-decoration: none;
}

// Indicators ==================================================================

.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}

.badge {
  &-warning,
  &-info {
    color: $white;
  }
}

.close {
  color: #fff;
  text-shadow: none;
  opacity: 0.5;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

// Progress bars ===============================================================

// Popovers      ===============================================================

.popover-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// Containers ==================================================================

.modal {
  &-header,
  &-footer {
    background-color: $white;
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  padding-right: 0px;
  padding-left: 0px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.card {
  margin: calc(5%) calc(10%);

  -webkit-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.75);

  &:hover {
    -webkit-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.75);
  }
}
