$color-main: #4A148C;
$color-secondary: #15cda8;
$grey: #4A4D57;
$normal: #15cda8;
$lightest: #d89cf6;
$light: #6a39c6;
$color-white: #f6eeee;
$purple: #54123b;
$dark-red: #84142d;
$light-red: #c02739;
